<template>
  <div data-theme="treasurebox" class="tw-bg-neutral/[.03] tw-w-full tw-h-full">
    <AppLayout>
      <template #leftTop>
        <svgo-notifications  />
      </template>
      <!-- <template #middleTop>
        <div class="tw-w-full tw-max-w-lg tw-mx-auto tw-hidden xl:tw-block">
          <AppMenus />
        </div>
      </template> -->
      <template #rightTop>
        <NuxtLink to="/me" class="tw-flex tw-items-center tw-text-neutral/40">
          <span class="tw-hidden lg:tw-inline">{{ email }}</span>
          <SvgoUserIcon class="tw-w-8 tw-h-8 tw-ml-2" />
        </NuxtLink>
      </template>
      <template #tip>
        <KycToast v-if="isSuccess && kycStatus !== KycStatus.Verified" />
      </template>
      <UIQuery :pending="isPending" :error="error" class="tw-pb-28">
        <slot />
      </UIQuery>
      <section class="tw-px-4 tw-pb-4 tw-pt-12 menu tw-block xl:tw-hidden">
        <div class="tw-w-full tw-max-w-lg tw-mx-auto">
          <AppMenus class="tw-shadow-lg" />
        </div>
      </section>
    </AppLayout>
  </div>
</template>
<script setup lang="ts">
const { error, isPending, isSuccess, kycStatus, data } = useUserInfo(true);
const email = computed(() => data.value?.email);
</script>
<style scoped>
.menu {
  background-image: linear-gradient(to top, #c8c8c8 0%, rgba(200, 200, 200, 0));
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 1000;
}
</style>
